import Widget from "@faintlines/widget";
import "@faintlines/widget/dist/index.css";

import { createElement } from "react";
import ReactDOM from "react-dom";

(function () {
    const VALID_HOSTS = ["localhost", "amazinganchors.com"];

    if (!VALID_HOSTS.includes(window.location.hostname)) {
        return;
    }

    const { config, storyConfig } = window.WIDGET || {};

    if (!config || !storyConfig) {
        return;
    }

    const element = document.createElement("div");
    document.body.appendChild(element);

    ReactDOM.render(createElement(Widget, { config, storyConfig }), element);
})();
